import { default as _91appId_937dGsi3TTReMeta } from "/opt/buildhome/repo/pages/apps/[appId].vue?macro=true";
import { default as indexv5IxOYNPQtMeta } from "/opt/buildhome/repo/pages/apps/index.vue?macro=true";
import { default as apps9PCuzGtWD8Meta } from "/opt/buildhome/repo/pages/apps.vue?macro=true";
import { default as successXuBflIzDlOMeta } from "/opt/buildhome/repo/pages/auth/success.vue?macro=true";
import { default as CloudBotGhVbc8VmvCMeta } from "/opt/buildhome/repo/pages/CloudBot.vue?macro=true";
import { default as _91id_93mdUjml4g6qMeta } from "/opt/buildhome/repo/pages/designers/[id].vue?macro=true";
import { default as chat_45v2wBYHYlIQFpMeta } from "/opt/buildhome/repo/pages/docks/chat-v2.vue?macro=true";
import { default as chat1hhU4BZOCWMeta } from "/opt/buildhome/repo/pages/docks/chat.vue?macro=true";
import { default as recent_45eventsDkMBo3BUGQMeta } from "/opt/buildhome/repo/pages/docks/recent-events.vue?macro=true";
import { default as tiktok_45chatlk3EZtxXC2Meta } from "/opt/buildhome/repo/pages/docks/tiktok-chat.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as _91collectionSlug_93et7pOYDJEIMeta } from "/opt/buildhome/repo/pages/library/collections/[collectionSlug].vue?macro=true";
import { default as collectionsDQq4grBH6XMeta } from "/opt/buildhome/repo/pages/library/collections/collections.vue?macro=true";
import { default as indexGoCDVXPIQ1Meta } from "/opt/buildhome/repo/pages/library/index.vue?macro=true";
import { default as _91itemId_93LsxzHtGsTOMeta } from "/opt/buildhome/repo/pages/library/items/[itemId].vue?macro=true";
import { default as libraryQcFipus8oUMeta } from "/opt/buildhome/repo/pages/library.vue?macro=true";
import { default as multistreamyLjFW0eflgMeta } from "/opt/buildhome/repo/pages/multistream.vue?macro=true";
import { default as overlayslU8ow7wejzMeta } from "/opt/buildhome/repo/pages/overlays.vue?macro=true";
import { default as sandboxErjgEKcDs7Meta } from "/opt/buildhome/repo/pages/sandbox.vue?macro=true";
import { default as settings4cDYwTsA31Meta } from "/opt/buildhome/repo/pages/settings.vue?macro=true";
import { default as stream_45labels5cfgTyYv70Meta } from "/opt/buildhome/repo/pages/stream-labels.vue?macro=true";
import { default as TippingTYt9PZwBmsMeta } from "/opt/buildhome/repo/pages/Tipping.vue?macro=true";
import { default as ultraTbquZr1WaDMeta } from "/opt/buildhome/repo/pages/ultra.vue?macro=true";
import { default as _91id_93J88XwZbvC4Meta } from "/opt/buildhome/repo/pages/widget-themes/[id].vue?macro=true";
import { default as indexnb5fjJCL2kMeta } from "/opt/buildhome/repo/pages/widget-themes/index.vue?macro=true";
import { default as widget_45themes4GauCwj4ptMeta } from "/opt/buildhome/repo/pages/widget-themes.vue?macro=true";
import { default as widgetsjKIGaIOBptMeta } from "/opt/buildhome/repo/pages/widgets.vue?macro=true";
export default [
  {
    name: apps9PCuzGtWD8Meta?.name,
    path: "/apps",
    component: () => import("/opt/buildhome/repo/pages/apps.vue"),
    children: [
  {
    name: "apps-appId",
    path: ":appId()",
    component: () => import("/opt/buildhome/repo/pages/apps/[appId].vue")
  },
  {
    name: "apps",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/apps/index.vue")
  }
]
  },
  {
    name: "auth-success",
    path: "/auth/success",
    meta: successXuBflIzDlOMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/success.vue")
  },
  {
    name: "CloudBot",
    path: "/CloudBot",
    meta: CloudBotGhVbc8VmvCMeta || {},
    component: () => import("/opt/buildhome/repo/pages/CloudBot.vue")
  },
  {
    name: "designers-id",
    path: "/designers/:id()",
    component: () => import("/opt/buildhome/repo/pages/designers/[id].vue")
  },
  {
    name: "docks-chat-v2",
    path: "/docks/chat-v2",
    meta: chat_45v2wBYHYlIQFpMeta || {},
    component: () => import("/opt/buildhome/repo/pages/docks/chat-v2.vue")
  },
  {
    name: "docks-chat",
    path: "/docks/chat",
    meta: chat1hhU4BZOCWMeta || {},
    component: () => import("/opt/buildhome/repo/pages/docks/chat.vue")
  },
  {
    name: "docks-recent-events",
    path: "/docks/recent-events",
    meta: recent_45eventsDkMBo3BUGQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/docks/recent-events.vue")
  },
  {
    name: "docks-tiktok-chat",
    path: "/docks/tiktok-chat",
    meta: tiktok_45chatlk3EZtxXC2Meta || {},
    component: () => import("/opt/buildhome/repo/pages/docks/tiktok-chat.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: libraryQcFipus8oUMeta?.name,
    path: "/library",
    component: () => import("/opt/buildhome/repo/pages/library.vue"),
    children: [
  {
    name: "library-collections-collectionSlug",
    path: "collections/:collectionSlug()",
    component: () => import("/opt/buildhome/repo/pages/library/collections/[collectionSlug].vue")
  },
  {
    name: "library-collections-collections",
    path: "collections/collections",
    component: () => import("/opt/buildhome/repo/pages/library/collections/collections.vue")
  },
  {
    name: "library",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/library/index.vue")
  },
  {
    name: "library-items-itemId",
    path: "items/:itemId()",
    component: () => import("/opt/buildhome/repo/pages/library/items/[itemId].vue")
  }
]
  },
  {
    name: "multistream",
    path: "/multistream",
    meta: multistreamyLjFW0eflgMeta || {},
    component: () => import("/opt/buildhome/repo/pages/multistream.vue")
  },
  {
    name: "overlays",
    path: "/overlays",
    component: () => import("/opt/buildhome/repo/pages/overlays.vue")
  },
  {
    name: "sandbox",
    path: "/sandbox",
    component: () => import("/opt/buildhome/repo/pages/sandbox.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settings4cDYwTsA31Meta || {},
    component: () => import("/opt/buildhome/repo/pages/settings.vue")
  },
  {
    name: "stream-labels",
    path: "/stream-labels",
    meta: stream_45labels5cfgTyYv70Meta || {},
    component: () => import("/opt/buildhome/repo/pages/stream-labels.vue")
  },
  {
    name: "Tipping",
    path: "/Tipping",
    meta: TippingTYt9PZwBmsMeta || {},
    component: () => import("/opt/buildhome/repo/pages/Tipping.vue")
  },
  {
    name: "ultra",
    path: "/ultra",
    meta: ultraTbquZr1WaDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/ultra.vue")
  },
  {
    name: widget_45themes4GauCwj4ptMeta?.name,
    path: "/widget-themes",
    component: () => import("/opt/buildhome/repo/pages/widget-themes.vue"),
    children: [
  {
    name: "widget-themes-id",
    path: ":id()",
    component: () => import("/opt/buildhome/repo/pages/widget-themes/[id].vue")
  },
  {
    name: "widget-themes",
    path: "",
    meta: indexnb5fjJCL2kMeta || {},
    component: () => import("/opt/buildhome/repo/pages/widget-themes/index.vue")
  }
]
  },
  {
    name: "widgets",
    path: "/widgets",
    meta: widgetsjKIGaIOBptMeta || {},
    component: () => import("/opt/buildhome/repo/pages/widgets.vue")
  }
]