<template>
  <button
    :class="
      styles({ variant, busy: hasState, size, condensed, class: props.class })
    "
  >
    <div
      :class="{ 'opacity-0': hasState }"
      style="
        display: inherit;
        align-items: inherit;
        justify-content: inherit;
        overflow: inherit;
        text-overflow: inherit;
        white-space: inherit;
        width: inherit;
      "
    >
      <slot />
    </div>

    <!-- <div v-if="busy" class="absolute inset-0 flex items-center justify-center">
      <Busy />
    </div> -->

    <div
      v-if="hasState"
      class="absolute inset-0 flex items-center justify-center"
    >
      <Transition name="pop" mode="out-in">
        <Busy v-if="state === 'busy' || busy" />
        <Icon v-else-if="state === 'success'" name="bx:check" class="h-6 w-6" />
      </Transition>
    </div>
  </button>
</template>

<script setup lang="ts">
import { type Props, styles } from "../Button/config";

const props = defineProps<Props>();

const hasState = computed(() => props.state !== undefined || props.busy);
</script>

<style lang="scss" scoped>
.pop-enter-active,
.pop-leave-active {
  transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.pop-enter-from {
  transform: scale(0.1);
}

.pop-leave-to {
  transform: scale(1);
}
</style>
