<template>
  <div class="fixed inset-0 flex flex-col items-center justify-center">
    <IconError class="mb-8 h-20 w-20 text-white/20" />

    <h1 class="mb-4 font-heading text-3xl font-medium">
      Uh oh! We've run into a problem...
    </h1>

    <p class="font-medium text-muted-foreground sm:max-w-xl">
      Please click below to restart the plugin.
    </p>

    <Button variant="primary" size="lg" class="mt-12" @click="restart">
      Restart Plugin
    </Button>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from "#app";

definePageMeta({
  //   title: "pages.title.overlays",
  //   description: "pages.description.overlays",
  layout: "blank",
});

const props = defineProps({
  error: Object as () => NuxtError,
});

const restart = () => {
  window.location.href = "/";
};
</script>
