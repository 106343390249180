<template>
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 3.05176e-05C10.866 3.05176e-05 14 3.13404 14 7.00003C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7.00003C0 3.13404 3.13401 3.05176e-05 7 3.05176e-05ZM6 7.00003C6 7.55232 6.44772 8.00003 7 8.00003C7.55228 8.00003 8 7.55232 8 7.00003V4.00003C8 3.44775 7.55228 3.00003 7 3.00003C6.44772 3.00003 6 3.44775 6 4.00003V7.00003ZM7 11C6.44772 11 6 10.5523 6 10C6 9.44775 6.44772 9.00003 7 9.00003C7.55228 9.00003 8 9.44775 8 10C8 10.5523 7.55228 11 7 11Z"
      fill="currentColor"
    />
  </svg>
</template>
