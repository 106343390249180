import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const { public: $env } = useRuntimeConfig();
  const router = useRouter();

  if (!$env.SENTRY_DSN) {
    console.warn("SENTRY_DSN not set, skipping Sentry initialization");
    return;
  }

  // // don't show feedback in docks
  // const includeFeedbackIntegration =
  //   router.currentRoute.value.meta.feedback !== false;

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: $env.SENTRY_DSN,
    environment: $env.APP_ENV,
    integrations: [
      // new Sentry.BrowserTracing({
      //   routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      // }),
      // new Sentry.Replay(),
      // ...(includeFeedbackIntegration
      //   ? [
      //       Sentry.feedbackIntegration({
      //         showBranding: false,
      //         colorScheme: "dark",
      //         buttonLabel: "Send Feedback",
      //         submitButtonLabel: "Send Feedback",
      //         formTitle: "Send Feedback",
      //         messagePlaceholder:
      //           "Any feedback from your experience, or bugs you've encounted.",
      //         successMessageText: "Thank you for your feedback!",
      //       }),
      //     ]
      //   : []),
    ],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
});

type P = Parameters<typeof Sentry.captureException>[1];

export const captureException = (str: string, options?: P) => {
  Sentry.captureException(str, options);
  console.error(str);
  if (options) {
    console.error(options);
  }
};
