const variants = {
  primary: "bg-primary",
  neutral: "bg-secondary text-neutral-100",
  danger: "bg-red-400 text-black",
  ghost: "bg-transparent text-neutral-100 hover:bg-secondary",
  link: "bg-transparent text-neutral-300 underline-offset-4 hover:underline",
  outline: "bg-transparent text-neutral-300 outline outline-1 outline-white/10",
  "outline-primary":
    "bg-transparent text-primary outline outline-1 outline-primary/10",
  inline: "bg-transparent p-0 text-inherit",
  ultra:
    "bg-gradient-to-br from-[#2DE8B0] via-[#CBE953] to-[#FFAB48] to-[#FF5151] font-medium",
};

const sizes = {
  xs: "px-3 py-1.5 text-xs",
  sm: "px-3.5 py-1.5 text-sm",
  lg: "px-5 py-3 text-lg",
  xl: "px-6 py-4 text-xl",
};

export const styles = tv({
  base: "font-medium inline-flex items-center justify-center rounded-md hover:brightness-110 px-4 py-2 text-base bg-white text-black outline-none ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  variants: {
    variant: variants,
    size: sizes,
    condensed: {
      true: "px-2",
    },
    busy: {
      true: "pointer-events-none relative",
    },
  },
  compoundVariants: [
    { size: "xs", condensed: true, class: "px-1.5" },
    { size: "sm", condensed: true, class: "px-1.5" },
    { size: "lg", condensed: true, class: "px-3" },
    { size: "xl", condensed: true, class: "px-4" },
  ],
});

type Variants = keyof typeof variants;
type Sizes = keyof typeof sizes;

export type Props = {
  class?: any;
  variant?: Variants;
  size?: Sizes;
  busy?: boolean;
  state?: "busy" | "success";
  condensed?: boolean;
};
