<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="size"
    :height="size"
    :viewBox="`0 0 ${size} ${size}`"
    xml:space="preserve"
  >
    <rect
      :x="width / 2"
      y="25%"
      :width="width"
      height="50%"
      fill="currentColor"
      opacity="0.2"
    >
      <animate
        attributeName="opacity"
        values="0.2; 1; 0.2; 0.2"
        dur="1s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
      <animate
        attributeName="height"
        values="50%; 100%; 50%; 50%"
        dur="1s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
      <animate
        attributeName="y"
        values="25%; 0%; 25%; 25%"
        dur="1s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
    </rect>

    <rect
      :x="(size - width) / 2"
      y="25%"
      :width="width"
      height="50%"
      fill="currentColor"
      opacity="0.2"
    >
      <animate
        attributeName="opacity"
        values="0.2; 1; 0.2; 0.2"
        dur="1s"
        repeatCount="indefinite"
        begin="0.25s"
      ></animate>
      <animate
        attributeName="height"
        values="50%; 100%; 50%; 50%"
        dur="1s"
        repeatCount="indefinite"
        begin="0.25s"
      ></animate>
      <animate
        attributeName="y"
        values="25%; 0%; 25%; 25%"
        dur="1s"
        repeatCount="indefinite"
        begin="0.25s"
      ></animate>
    </rect>

    <rect
      :x="size - 1.5 * width"
      y="25%"
      :width="width"
      height="50%"
      fill="currentColor"
      opacity="0.2"
    >
      <animate
        attributeName="opacity"
        values="0.2; 1; 0.2; 0.2"
        dur="1s"
        repeatCount="indefinite"
        begin="0.5s"
      ></animate>
      <animate
        attributeName="height"
        values="50%; 100%; 50%; 50%"
        dur="1s"
        repeatCount="indefinite"
        begin="0.5s"
      ></animate>
      <animate
        attributeName="y"
        values="25%; 0%; 25%; 25%"
        dur="1s"
        repeatCount="indefinite"
        begin="0.5s"
      ></animate>
    </rect>
  </svg>
</template>

<script>
export default {
  data() {
    return {
      size: 24,
      width: 4,
    };
  },
};
</script>
